export default {
  name: 'MainFooter',
  props: {
    startingYear: {
      type: Number,
      default: 2009,
    },
    currentYear: {
      type: Number,
      default: () => new Date().getFullYear(),
    },
    termsUrl: {
      type: String,
      default: 'https://tos.flightscope.com/',
    },
    social: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      default: () => [
        {
          href: {
            label: 'Privacy Policy',
            href: 'https://docs.google.com/document/d/e/2PACX-1vQxg9R0gGfe5JZuBSr6CzLEAp6w2BKG8plVZ5igHT15ssEgyDEV6UzdvTLwDwZG57t2GBGNeOwEWfVq/pub',
            target: '_blank',
          },
        },
        {
          href: {
            label: 'Terms of Use',
            href: 'https://docs.google.com/document/d/e/2PACX-1vRvTlAmpIPGj31wJQuNLWI7hfu7AANeciANoZRzx2I_IxH7T166YDvh-gmTe0DoMIT04Lw3cEmu7-cY/pub',
            target: '_blank',
          },
        },
        {
          href: {
            label: 'Disclaimer',
            href: 'https://docs.google.com/document/d/e/2PACX-1vTaxfkhQkwAXiIzQdwktVz3JQr6ABVgITmV6f4uaFQIe0G1q79ndJXPEYqPIu1UaJssdJNSiXwmDpr3/pub',
            target: '_blank',
          },
        },
        {
          href: {
            label: 'Cookies Policy',
            href: 'https://docs.google.com/document/d/e/2PACX-1vTo5RgU1-f5L9-PGVavP2VLBFB_Py62pVC7zoESm6VbS822KeJGv0qj6XOqvcCy2dFtcA3ZEcv_nZRs/pub',
            target: '_blank',
          },
        },
      ],
    },
  },

  computed: {
    socialItems() {
      let href;
      let iconUrl;

      return this.social.map(({ type, channelID }) => {
        switch (type) {
          case 'facebook':
            href = `https://www.facebook.com/${channelID}`;
            iconUrl = '/img/social-icons/facebook-svgomg.svg';
            break;
          case 'twitter':
            href = `https://twitter.com/${channelID}`;
            iconUrl = '/img/social-icons/twitter-svgomg.svg';
            break;
          case 'instagram':
            href = `https://www.instagram.com/${channelID}`;
            iconUrl = '/img/social-icons/instagram-svgomg.svg';
            break;
          default:
            href = '';
            iconUrl = '';
        }

        return {
          href,
          iconUrl,
        };
      });
    },
  }
};
